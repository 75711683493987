// src/api/laporanMutasiHasilProduksiService.js 

import axiosInstance from './axiosInstance';

// const API_URL = process.env.REACT_APP_API_URL;

export const getLaporan = async (filters = {}) => {
    try {
        const response = await axiosInstance.get('/api/laporanMutasiHasilProduksi', { params: filters });
        return response.data;
    } catch (error) {
        console.error("Error fetching laporan:", error);
        return [];
    }
};

export const getLaporanById = async (id) => {
    try {
        const response = await axiosInstance.get(`/api/laporanMutasiHasilProduksi/${id}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching laporan by id:", error);
        return null;
    }
};

export const createLaporan = async (data) => {
    try {
        const response = await axiosInstance.post('/api/laporanMutasiHasilProduksi', data);
        return response.data;
    } catch (error) {
        console.error("Error creating laporan:", error);
        throw error;
    }
};

export const updateLaporan = async (id, data) => {
    try {
        const response = await axiosInstance.put(`/api/laporanMutasiHasilProduksi/${id}`, data);
        return response.data;
    } catch (error) {
        console.error("Error updating laporan:", error);
        throw error;
    }
};

export const deleteLaporan = async (id) => {
    try {
        const response = await axiosInstance.delete(`/api/laporanMutasiHasilProduksi/${id}`);
        return response.data;
    } catch (error) {
        console.error("Error deleting laporan:", error);
        throw error;
    }
};
