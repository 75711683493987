// src/components/Customers/CustomerFilterForm.js
import React, { useState } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';

const CustomerFilterForm = ({ onSubmit }) => {
  const [criteria, setCriteria] = useState({ field: 'name', type: 'contains', value: '' });

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setCriteria((prevCriteria) => ({ ...prevCriteria, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(criteria);
  };

  return (
    <Form onSubmit={handleSubmit} className="mb-3">
      <Row>
        <Col>
          <Form.Group controlId="field">
            <Form.Label>Field</Form.Label>
            <Form.Control as="select" name="field" value={criteria.field} onChange={handleFilterChange}>
              <option value="name">Name</option>
              <option value="email">Email</option>
              <option value="phone">Phone</option>
              <option value="address">Address</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="type">
            <Form.Label>Type</Form.Label>
            <Form.Control as="select" name="type" value={criteria.type} onChange={handleFilterChange}>
              <option value="exact">Exact</option>
              <option value="contains">Contains</option>
              <option value="lessThan">Less than</option>
              <option value="greaterThan">Greater than</option>
              <option value="between">Between</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="value">
            <Form.Label>Value</Form.Label>
            {criteria.type === 'between' ? (
              <>
                <Form.Control
                  type="text"
                  placeholder="Min"
                  name="valueMin"
                  value={criteria.valueMin}
                  onChange={handleFilterChange}
                />
                <Form.Control
                  type="text"
                  placeholder="Max"
                  name="valueMax"
                  value={criteria.valueMax}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </>
            ) : (
              <Form.Control
                type="text"
                name="value"
                value={criteria.value}
                onChange={handleFilterChange}
              />
            )}
          </Form.Group>
        </Col>
      </Row>
      <Button type="submit" className="mt-3">Filter</Button>
    </Form>
  );
};

export default CustomerFilterForm;
