// src/utils/auth.js

import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const refreshToken = async () => {
  try {
    const token = localStorage.getItem('refreshToken');
    const response = await axios.post(`${API_URL}/api/auth/refresh-token`, { refreshToken: token });

    // Store the new access token and refresh token
    localStorage.setItem('accessToken', response.data.accessToken);
    localStorage.setItem('refreshToken', response.data.refreshToken);

    return response.data.accessToken;
  } catch (error) {
    console.error('Failed to refresh token:', error);
    throw error;
  }
};
