// src/api/laporanKegiatanSubkontrakService.js

import axiosInstance from './axiosInstance';

const getLaporan = async (filters = {}) => {
  try {
    const query = new URLSearchParams(filters).toString();
    const response = await axiosInstance.get(`/api/laporanKegiatanSubkontrak?${query}`);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || error.message;
  }
};

const getLaporanById = async (id) => {
  try {
    const response = await axiosInstance.get(`/api/laporanKegiatanSubkontrak/${id}`);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || error.message;
  }
};

const createLaporan = async (data) => {
  try {
    const response = await axiosInstance.post('/api/laporanKegiatanSubkontrak', data);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || error.message;
  }
};

const updateLaporan = async (id, data) => {
  try {
    const response = await axiosInstance.put(`/api/laporanKegiatanSubkontrak/${id}`, data);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || error.message;
  }
};

const deleteLaporan = async (id) => {
  try {
    const response = await axiosInstance.delete(`/api/laporanKegiatanSubkontrak/${id}`);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || error.message;
  }
};

export { getLaporan, getLaporanById, createLaporan, updateLaporan, deleteLaporan };
