import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getLaporanById, updateLaporan } from '../../api/laporanPemasukanBahanBakuService';

const EditLaporan = () => {
    const { uuid } = useParams();
    const navigate = useNavigate();
    const [laporan, setLaporan] = useState(null);

    useEffect(() => {
        fetchLaporan();
    }, []);

    const fetchLaporan = async () => {
        try {
            const response = await getLaporanById(uuid);
            setLaporan(response);
        } catch (error) {
            console.error("Error fetching laporan:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLaporan({ ...laporan, [name]: value });
    };

    const handleSave = async () => {
        try {
            await updateLaporan(uuid, laporan);
            navigate('/laporan-pemasukan-bahan-baku');
        } catch (error) {
            console.error("Error updating laporan:", error);
        }
    };

    if (!laporan) return <div>Loading...</div>;

    return (
        <div>
            <h3>Edit Laporan</h3>
            <form>
                <div className="form-group">
                    <label>Tanggal Rekam</label>
                    <input
                        type="date"
                        name="tanggalRekam"
                        value={new Date(laporan.tanggalRekam).toISOString().split('T')[0]}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Jenis Dokumen</label>
                    <input
                        type="text"
                        name="jenisDokumen"
                        value={laporan.jenisDokumen}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Dokumen Nomor</label>
                    <input
                        type="text"
                        name="dokumenNomor"
                        value={laporan.dokumenNomor}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Pabean Tanggal</label>
                    <input
                        type="date"
                        name="pabeanTanggal"
                        value={new Date(laporan.pabeanTanggal).toISOString().split('T')[0]}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Kode HS</label>
                    <input
                        type="text"
                        name="kodeHS"
                        value={laporan.kodeHS}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Pabean Nomor Seri Barang</label>
                    <input
                        type="number"
                        name="pabeanNomorSeriBarang"
                        value={laporan.pabeanNomorSeriBarang}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Bukti Penerimaan Barang Nomor</label>
                    <input
                        type="text"
                        name="buktiPenerimaanBarangNomor"
                        value={laporan.buktiPenerimaanBarangNomor}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Bukti Penerimaan Barang Tanggal</label>
                    <input
                        type="date"
                        name="buktiPenerimaanBarangTanggal"
                        value={new Date(laporan.buktiPenerimaanBarangTanggal).toISOString().split('T')[0]}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Kode Barang</label>
                    <input
                        type="text"
                        name="kodeBarang"
                        value={laporan.kodeBarang}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Nama Barang</label>
                    <input
                        type="text"
                        name="namaBarang"
                        value={laporan.namaBarang}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Satuan</label>
                    <input
                        type="text"
                        name="satuan"
                        value={laporan.satuan}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Jumlah</label>
                    <input
                        type="number"
                        name="jumlah"
                        value={laporan.jumlah}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Mata Uang</label>
                    <input
                        type="text"
                        name="mataUang"
                        value={laporan.mataUang}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Nilai Barang</label>
                    <input
                        type="number"
                        name="nilaiBarang"
                        value={laporan.nilaiBarang}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Gudang</label>
                    <input
                        type="text"
                        name="gudang"
                        value={laporan.gudang}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Negara Asal Barang</label>
                    <input
                        type="text"
                        name="negaraAsalBarang"
                        value={laporan.negaraAsalBarang}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <button type="button" onClick={handleSave} className="btn btn-primary">Save</button>
            </form>
        </div>
    );
};

export default EditLaporan;
