// src/components/Dashboard/Dashboard.js
import React from 'react';

const Dashboard = () => {
  return (
    <div className="mt-5">
      <h2>Dashboard</h2>
      <p>Welcome to the dashboard!</p>
      Company Number: <br/>
					NPPKP: <br/>
					NPWP: <b>01.784.746.8 - 441.000</b><br/>
          <br/>

					Description: <br/>
          UTC Aerospace Systems is committed to creating a supply chain that delivers best-in-class products and services to our customers. <br/>
          To achieve excellence in cost, quality and delivery, and ultimately that competitive advantage, we are focused on optimizing our processes and tools, managing supplier performance and investing in talent and development.<br/>
          <br/>
					Address: JL. Soekarno Hatta No. 35 RT. 004 RW. 005 Mekar Mulya Panyileukan, Kota Bandung<br/>
					Phone Number: 022 - 86020100<br/>
    </div>
  );
};

export default Dashboard;
