import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getLaporan, deleteLaporan } from '../../api/laporanBarangRejectDanScrapService';
import * as XLSX from 'xlsx';
import { Table, Button, Pagination } from 'react-bootstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';

const LaporanBarangRejectDanScrapList = () => {
    const [laporan, setLaporan] = useState([]);
    const [filteredLaporan, setFilteredLaporan] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [tempStartDate, setTempStartDate] = useState('');
    const [tempEndDate, setTempEndDate] = useState('');
    const [filtered, setFiltered] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [sortField, setSortField] = useState('kodeBarang');
    const [sortOrder, setSortOrder] = useState('asc');
    const navigate = useNavigate();

    useEffect(() => {
        fetchLaporan();
    }, [currentPage, sortField, sortOrder]);

    useEffect(() => {
        applyFilter();
    }, [tempStartDate, tempEndDate, laporan]);

    const fetchLaporan = async () => {
        try {
            const filters = {};
            if (startDate) filters.startDate = startDate;
            if (endDate) filters.endDate = endDate;

            const response = await getLaporan(filters);
            if (Array.isArray(response)) {
                setLaporan(response);
            } else {
                console.error("Expected an array but got:", typeof response);
                setLaporan([]);
                setFilteredLaporan([]);
            }
        } catch (error) {
            console.error("Error fetching laporan:", error);
            setLaporan([]);
            setFilteredLaporan([]);
        }
    };

    const applyFilter = () => {
        let filteredData = laporan;
        if (tempStartDate) {
            filteredData = filteredData.filter(item => new Date(item.tanggal) >= new Date(tempStartDate));
        }
        if (tempEndDate) {
            filteredData = filteredData.filter(item => new Date(item.tanggal) <= new Date(tempEndDate));
        }
        setFilteredLaporan(filteredData);
    };

    const handleFilter = () => {
        setStartDate(tempStartDate);
        setEndDate(tempEndDate);
        setFiltered(true);
    };

    const handleReset = () => {
        setStartDate('');
        setEndDate('');
        setTempStartDate('');
        setTempEndDate('');
        setFiltered(false);
        fetchLaporan();
    };

    const handleDelete = async (id) => {
        try {
            await deleteLaporan(id);
            fetchLaporan();
        } catch (error) {
            console.error("Error deleting laporan:", error);
        }
    };

    const handleEdit = (id) => {
        navigate(`/laporan-barang-reject-scrap/edit/${id}`);
    };

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(
            filteredLaporan.map((laporan, index) => ({
                No: index + 1,
                KodeBarang: laporan.kodeBarang,
                NamaBarang: laporan.namaBarang,
                Satuan: laporan.Satuan,
                SaldoAwal: laporan.saldoAwal,
                Pemasukan: laporan.pemasukan,
                Pengeluaran: laporan.pengeluaran,
                Penyesuaian: laporan.penyesuaian,
                SaldoBuku: laporan.saldoBuku,
                StockOpname: laporan.stockOpname,
                Selisih: laporan.selisih,
                Keterangan: laporan.Keterangan,
            }))
        );
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Laporan Barang Reject Dan Scrap');
        XLSX.writeFile(workbook, 'laporan_barang_reject_dan_scrap.xlsx');
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSort = (field) => {
        const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(order);
    };

    const getSortedData = () => {
        const sortedData = [...filteredLaporan].sort((a, b) => {
            if (a[sortField] < b[sortField]) return sortOrder === 'asc' ? -1 : 1;
            if (a[sortField] > b[sortField]) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });
        return sortedData;
    };

    const getPagedData = () => {
        const sortedData = getSortedData();
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return sortedData.slice(startIndex, endIndex);
    };

    const totalPages = Math.ceil(filteredLaporan.length / itemsPerPage);

    return (
        <div>
            <h2>Laporan Barang Reject Dan Scrap</h2>

            <div className="mb-3">
                <label htmlFor="startDate" className="form-label">Start Date</label>
                <input
                    type="date"
                    id="startDate"
                    value={tempStartDate}
                    onChange={(e) => setTempStartDate(e.target.value)}
                    className="form-control"
                />
            </div>

            <div className="mb-3">
                <label htmlFor="endDate" className="form-label">End Date</label>
                <input
                    type="date"
                    id="endDate"
                    value={tempEndDate}
                    onChange={(e) => setTempEndDate(e.target.value)}
                    className="form-control"
                />
            </div>

            <Button onClick={handleFilter} className="btn btn-primary">Filter</Button>
            <Button onClick={handleReset} className="btn btn-secondary ms-2">Reset Filter</Button>
            {filtered && (
                <Button onClick={exportToExcel} className="btn btn-success ms-2">Export to Excel</Button>
            )}

            {filtered && (
                <div className="table-responsive mt-3">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th onClick={() => handleSort('kodeBarang')}>
                                    Kode Barang
                                    {sortField === 'kodeBarang' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                </th>
                                <th onClick={() => handleSort('namaBarang')}>
                                    Nama Barang
                                    {sortField === 'namaBarang' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                </th>
                                <th onClick={() => handleSort('Satuan')}>
                                    Satuan
                                    {sortField === 'Satuan' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                </th>
                                <th onClick={() => handleSort('saldoAwal')}>
                                    Saldo Awal
                                    {sortField === 'saldoAwal' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                </th>
                                <th onClick={() => handleSort('pemasukan')}>
                                    Pemasukan
                                    {sortField === 'pemasukan' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                </th>
                                <th onClick={() => handleSort('pengeluaran')}>
                                    Pengeluaran
                                    {sortField === 'pengeluaran' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                </th>
                                <th onClick={() => handleSort('penyesuaian')}>
                                    Penyesuaian
                                    {sortField === 'penyesuaian' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                </th>
                                <th onClick={() => handleSort('saldoBuku')}>
                                    Saldo Buku
                                    {sortField === 'saldoBuku' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                </th>
                                <th onClick={() => handleSort('stockOpname')}>
                                    Stock Opname
                                    {sortField === 'stockOpname' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                </th>
                                <th onClick={() => handleSort('selisih')}>
                                    Selisih
                                    {sortField === 'selisih' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                </th>
                                <th onClick={() => handleSort('Keterangan')}>
                                    Keterangan
                                    {sortField === 'Keterangan' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                </th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getPagedData().map((item, index) => (
                                <tr key={item.uuid}>
                                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                    <td>{item.kodeBarang}</td>
                                    <td>{item.namaBarang}</td>
                                    <td>{item.Satuan}</td>
                                    <td style={{ textAlign: 'right' }}>{item.saldoAwal}</td>
                                    <td style={{ textAlign: 'right' }}>{item.pemasukan}</td>
                                    <td style={{ textAlign: 'right' }}>{item.pengeluaran}</td>
                                    <td style={{ textAlign: 'right' }}>{item.penyesuaian}</td>
                                    <td style={{ textAlign: 'right' }}>{item.saldoBuku}</td>
                                    <td style={{ textAlign: 'right' }}>{item.stockOpname}</td>
                                    <td style={{ textAlign: 'right' }}>{item.selisih}</td>
                                    <td>{item.Keterangan}</td>
                                    <td>
                                        <Button onClick={() => handleEdit(item.id)} className="btn btn-primary w-100">Edit</Button>
                                        <Button onClick={() => handleDelete(item.id)} className="btn btn-danger w-100 ">Delete</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            )}

            {filtered && (
                <Pagination className="mt-3">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => handlePageChange(index + 1)}>
                            {index + 1}
                        </Pagination.Item>
                    ))}
                </Pagination>
            )}
        </div>
    );
};

export default LaporanBarangRejectDanScrapList;
