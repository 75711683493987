import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getLaporan, deleteLaporan } from '../../api/laporanKegiatanSubkontrakService';
import * as XLSX from 'xlsx';
import { Table, Button, Pagination } from 'react-bootstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';

const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat('en-GB', options).format(new Date(dateString));
};

const LaporanKegiatanSubkontrakList = () => {
    const [laporan, setLaporan] = useState([]);
    const [filteredLaporan, setFilteredLaporan] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [tempStartDate, setTempStartDate] = useState('');
    const [tempEndDate, setTempEndDate] = useState('');
    const [filtered, setFiltered] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [sortField, setSortField] = useState('TanggalBuktiPengeluaranBarang');
    const [sortOrder, setSortOrder] = useState('asc');
    const navigate = useNavigate();

    useEffect(() => {
        fetchLaporan();
    }, [currentPage, sortField, sortOrder]);

    useEffect(() => {
        applyFilter();
    }, [tempStartDate, tempEndDate, laporan]);

    const fetchLaporan = async () => {
        try {
            const filters = {};
            if (startDate) filters.startDate = startDate;
            if (endDate) filters.endDate = endDate;

            const response = await getLaporan(filters);
            console.log("API Response:", response);
            if (Array.isArray(response)) {
                setLaporan(response);
            } else {
                console.error("Expected an array but got:", typeof response);
                setLaporan([]);
                setFilteredLaporan([]);
            }
        } catch (error) {
            console.error("Error fetching laporan:", error);
            setLaporan([]);
            setFilteredLaporan([]);
        }
    };

    const applyFilter = () => {
        let filteredData = laporan;
        if (tempStartDate) {
            filteredData = filteredData.filter(item => new Date(item.TanggalBuktiPengeluaranBarang) >= new Date(tempStartDate));
        }
        if (tempEndDate) {
            filteredData = filteredData.filter(item => new Date(item.TanggalBuktiPengeluaranBarang) <= new Date(tempEndDate));
        }
        setFilteredLaporan(filteredData);
    };

    const handleFilter = () => {
        setStartDate(tempStartDate);
        setEndDate(tempEndDate);
        setFiltered(true);
    };

    const handleReset = () => {
        setStartDate('');
        setEndDate('');
        setTempStartDate('');
        setTempEndDate('');
        setFiltered(false);
        fetchLaporan();
    };

    const handleDelete = async (id) => {
        try {
            await deleteLaporan(id);
            fetchLaporan();
        } catch (error) {
            console.error("Error deleting laporan:", error);
        }
    };

    const handleEdit = (id) => {
        navigate(`/laporan-kegiatan-subkontrak/edit/${id}`);
    };

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(
            filteredLaporan.map((laporan, index) => ({
                No: index + 1,
                NomorBuktiPengeluaranBarang: laporan.NomorBuktiPengeluaranBarang,
                TanggalBuktiPengeluaranBarang: formatDate(laporan.TanggalBuktiPengeluaranBarang),
                KodeBarang: laporan.kodeBarang,
                NamaBarang: laporan.namaBarang,
                Satuan: laporan.Satuan,
                Disubkontrakkan: laporan.Disubkontrakkan,
                PenerimaSubKontrak: laporan.PenerimaSubKontrak,
            }))
        );
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Laporan Kegiatan Subkontrak');
        XLSX.writeFile(workbook, 'laporan_kegiatan_subkontrak.xlsx');
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSort = (field) => {
        const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(order);
    };

    const getSortedData = () => {
        const sortedData = [...filteredLaporan].sort((a, b) => {
            if (a[sortField] < b[sortField]) return sortOrder === 'asc' ? -1 : 1;
            if (a[sortField] > b[sortField]) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });
        return sortedData;
    };

    const getPagedData = () => {
        const sortedData = getSortedData();
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return sortedData.slice(startIndex, endIndex);
    };

    const totalPages = Math.ceil(filteredLaporan.length / itemsPerPage);

    return (
        <div>
            <h2>Laporan Kegiatan Subkontrak</h2>

            <div className="mb-3">
                <label htmlFor="startDate" className="form-label">Start Date</label>
                <input
                    type="date"
                    id="startDate"
                    value={tempStartDate}
                    onChange={(e) => setTempStartDate(e.target.value)}
                    className="form-control"
                />
            </div>

            <div className="mb-3">
                <label htmlFor="endDate" className="form-label">End Date</label>
                <input
                    type="date"
                    id="endDate"
                    value={tempEndDate}
                    onChange={(e) => setTempEndDate(e.target.value)}
                    className="form-control"
                />
            </div>

            <Button onClick={handleFilter} className="btn btn-primary">Filter</Button>
            <Button onClick={handleReset} className="btn btn-secondary ms-2">Reset Filter</Button>
            {filtered && (
                <Button onClick={exportToExcel} className="btn btn-success ms-2">Export to Excel</Button>
            )}

            {filtered && (
                <>
                    <div className="table-responsive mt-3">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th onClick={() => handleSort('TanggalBuktiPengeluaranBarang')}>
                                        Tanggal Bukti Pengeluaran Barang
                                        {sortField === 'TanggalBuktiPengeluaranBarang' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                    </th>
                                    <th onClick={() => handleSort('NomorBuktiPengeluaranBarang')}>
                                        Nomor Bukti Pengeluaran Barang
                                        {sortField === 'NomorBuktiPengeluaranBarang' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                    </th>
                                    <th onClick={() => handleSort('kodeBarang')}>
                                        Kode Barang
                                        {sortField === 'kodeBarang' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                    </th>
                                    <th onClick={() => handleSort('namaBarang')}>
                                        Nama Barang
                                        {sortField === 'namaBarang' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                    </th>
                                    <th onClick={() => handleSort('Satuan')}>
                                        Satuan
                                        {sortField === 'Satuan' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                    </th>
                                    <th onClick={() => handleSort('Disubkontrakkan')}>
                                        Disubkontrakkan
                                        {sortField === 'Disubkontrakkan' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                    </th>
                                    <th onClick={() => handleSort('PenerimaSubKontrak')}>
                                        Penerima Sub Kontrak
                                        {sortField === 'PenerimaSubKontrak' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                    </th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getPagedData().map((laporan, index) => (
                                    <tr key={laporan.id}>
                                        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                        <td>{formatDate(laporan.TanggalBuktiPengeluaranBarang)}</td>
                                        <td>{laporan.NomorBuktiPengeluaranBarang}</td>
                                        <td>{laporan.kodeBarang}</td>
                                        <td>{laporan.namaBarang}</td>
                                        <td>{laporan.Satuan}</td>
                                        <td style={{ textAlign: 'right' }}>{laporan.Disubkontrakkan}</td>
                                        <td>{laporan.PenerimaSubKontrak}</td>
                                        <td>
                                            <Button variant="warning" onClick={() => handleEdit(laporan.id)}>Edit</Button>
                                            <Button variant="danger"   onClick={() => handleDelete(laporan.id)}>Delete</Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <Pagination>
                        {[...Array(totalPages)].map((_, index) => (
                            <Pagination.Item
                                key={index + 1}
                                active={index + 1 === currentPage}
                                onClick={() => handlePageChange(index + 1)}
                            >
                                {index + 1}
                            </Pagination.Item>
                        ))}
                    </Pagination>
                </>
            )}
        </div>
    );
};

export default LaporanKegiatanSubkontrakList;
