import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getLaporan, deleteLaporan } from '../../api/laporanPengeluaranHasilProduksiService';
import { Table, Button, Pagination } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import '@fortawesome/fontawesome-free/css/all.min.css';

const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat('en-GB', options).format(new Date(dateString));
};

const LaporanPengeluaranHasilProduksiList = () => {
    const [laporan, setLaporan] = useState([]);
    const [filteredLaporan, setFilteredLaporan] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [tempStartDate, setTempStartDate] = useState('');
    const [tempEndDate, setTempEndDate] = useState('');
    const [filtered, setFiltered] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [sortField, setSortField] = useState('buktiPengeluaranBarangTanggal');
    const [sortOrder, setSortOrder] = useState('asc');
    const navigate = useNavigate();

    useEffect(() => {
        fetchLaporan();
    }, [currentPage, sortField, sortOrder]);

    useEffect(() => {
        applyFilter();
    }, [tempStartDate, tempEndDate, laporan]);

    const fetchLaporan = async () => {
        try {
            const filters = {};
            if (startDate) filters.startDate = startDate;
            if (endDate) filters.endDate = endDate;

            const response = await getLaporan(filters);
            if (Array.isArray(response)) {
                setLaporan(response);
            } else {
                console.error("Expected an array but got:", typeof response);
                setLaporan([]);
                setFilteredLaporan([]);
            }
        } catch (error) {
            console.error("Error fetching laporan:", error);
            setLaporan([]);
            setFilteredLaporan([]);
        }
    };

    const applyFilter = () => {
        let filteredData = laporan;
        if (tempStartDate) {
            filteredData = filteredData.filter(item => new Date(item.buktiPengeluaranBarangTanggal) >= new Date(tempStartDate));
        }
        if (tempEndDate) {
            filteredData = filteredData.filter(item => new Date(item.buktiPengeluaranBarangTanggal) <= new Date(tempEndDate));
        }
        setFilteredLaporan(filteredData);
    };

    const handleFilter = () => {
        setStartDate(tempStartDate);
        setEndDate(tempEndDate);
        setFiltered(true);
    };

    const handleReset = () => {
        setStartDate('');
        setEndDate('');
        setTempStartDate('');
        setTempEndDate('');
        setFiltered(false);
        fetchLaporan();
    };

    const handleDelete = async (id) => {
        try {
            await deleteLaporan(id);
            fetchLaporan();
        } catch (error) {
            console.error("Error deleting laporan:", error);
        }
    };

    const handleEdit = (id) => {
        navigate(`/edit-laporan-pengeluaran-hasil-produksi/${id}`);
    };

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(
            filteredLaporan.map((laporan, index) => ({
                No: index + 1,
                PebNomor: laporan.pebNomor,
                PebTanggal: formatDate(laporan.pebTanggal),
                BuktiPengeluaranBarangNomor: laporan.buktiPengeluaranBarangNomor,
                BuktiPengeluaranBarangTanggal: formatDate(laporan.buktiPengeluaranBarangTanggal),
                PembeliPenerima: laporan.pembeliPenerima,
                NegaraTujuan: laporan.negaraTujuan,
                KodeBarang: laporan.kodeBarang,
                NamaBarang: laporan.namaBarang,
                Satuan: laporan.satuan,
                Jumlah: laporan.jumlah,
                MataUang: laporan.mataUang,
                NilaiBarang: laporan.nilaiBarang,
            }))
        );
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Lap Pengeluaran Hasil Prod');
        XLSX.writeFile(workbook, 'laporan_pengeluaran_hasil_produksi.xlsx');
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSort = (field) => {
        const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(order);
    };

    const getSortedData = () => {
        const sortedData = [...filteredLaporan].sort((a, b) => {
            if (a[sortField] < b[sortField]) return sortOrder === 'asc' ? -1 : 1;
            if (a[sortField] > b[sortField]) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });
        return sortedData;
    };

    const getPagedData = () => {
        const sortedData = getSortedData();
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return sortedData.slice(startIndex, endIndex);
    };

    const totalPages = Math.ceil(filteredLaporan.length / itemsPerPage);

    return (
        <div>
            <h2>Laporan Pengeluaran Hasil Produksi</h2>

            <div className="mb-3">
                <label htmlFor="startDate" className="form-label">Start Date</label>
                <input
                    type="date"
                    id="startDate"
                    value={tempStartDate}
                    onChange={(e) => setTempStartDate(e.target.value)}
                    className="form-control"
                />
            </div>
            
            <div className="mb-3">
                <label htmlFor="endDate" className="form-label">End Date</label>
                <input
                    type="date"
                    id="endDate"
                    value={tempEndDate}
                    onChange={(e) => setTempEndDate(e.target.value)}
                    className="form-control"
                />
            </div>
            
            <Button onClick={handleFilter} className="btn btn-primary">Filter</Button>
            <Button onClick={handleReset} className="btn btn-secondary">Reset Filter</Button>
            {filtered && (
                <Button onClick={exportToExcel} className="btn btn-success ms-2">Export to Excel</Button>
            )}

            {filtered && (
                <>
                    <div className="table-responsive mt-3">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th onClick={() => handleSort('pebNomor')}>PEB Nomor</th>
                                    <th onClick={() => handleSort('pebTanggal')}>PEB Tanggal</th>
                                    <th onClick={() => handleSort('buktiPengeluaranBarangNomor')}>Bukti Pengeluaran Barang Nomor</th>
                                    <th onClick={() => handleSort('buktiPengeluaranBarangTanggal')}>Bukti Pengeluaran Barang Tanggal</th>
                                    <th onClick={() => handleSort('pembeliPenerima')}>Pembeli Penerima</th>
                                    <th onClick={() => handleSort('negaraTujuan')}>Negara Tujuan</th>
                                    <th onClick={() => handleSort('kodeBarang')}>Kode Barang</th>
                                    <th onClick={() => handleSort('namaBarang')}>Nama Barang</th>
                                    <th onClick={() => handleSort('satuan')}>Satuan</th>
                                    <th onClick={() => handleSort('jumlah')}>Jumlah</th>
                                    <th onClick={() => handleSort('mataUang')}>Mata Uang</th>
                                    <th onClick={() => handleSort('nilaiBarang')}>Nilai Barang</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getPagedData().map((laporan, index) => (
                                    <tr key={laporan.id}>
                                        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                        <td>{laporan.pebNomor}</td>
                                        <td>{formatDate(laporan.pebTanggal)}</td>
                                        <td>{laporan.buktiPengeluaranBarangNomor}</td>
                                        <td>{formatDate(laporan.buktiPengeluaranBarangTanggal)}</td>
                                        <td>{laporan.pembeliPenerima}</td>
                                        <td>{laporan.negaraTujuan}</td>
                                        <td>{laporan.kodeBarang}</td>
                                        <td>{laporan.namaBarang}</td>
                                        <td>{laporan.satuan}</td>
                                        <td>{laporan.jumlah}</td>
                                        <td>{laporan.mataUang}</td>
                                        <td>{laporan.nilaiBarang}</td>
                                        <td>
                                            <Button variant="warning" onClick={() => handleEdit(laporan.id)}>
                                                <i className="fas fa-edit"></i>
                                            </Button>
                                            <Button variant="danger" onClick={() => handleDelete(laporan.id)} className="">
                                                <i className="fas fa-trash-alt"></i>
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>

                    <Pagination>
                        {Array.from({ length: totalPages }, (_, index) => (
                            <Pagination.Item
                                key={index + 1}
                                active={index + 1 === currentPage}
                                onClick={() => handlePageChange(index + 1)}
                            >
                                {index + 1}
                            </Pagination.Item>
                        ))}
                    </Pagination>
                </>
            )}
        </div>
    );
};

export default LaporanPengeluaranHasilProduksiList;
