// src/api/auth.js

import axiosInstance from './axiosInstance';

export const login = async (email, password) => {
  return axiosInstance.post('/api/auth/login', { email, password });
};

export const register = async (email, password) => {
  return axiosInstance.post('/api/auth/register', { email, password });
};

export const changePassword = async (oldPassword, newPassword) => {
  return axiosInstance.post('/api/auth/change-password', { oldPassword, newPassword });
};

export const logout = async () => {
  return axiosInstance.post('/api/auth/logout');
};
