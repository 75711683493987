import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getLaporanById, updateLaporan } from '../../api/laporanPengeluaranHasilProduksiService';
import { Form, Button } from 'react-bootstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';

const EditLaporanPengeluaranHasilProduksi = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [laporan, setLaporan] = useState({
        pebNomor: '',
        pebTanggal: '',
        buktiPengeluaranBarangNomor: '',
        buktiPengeluaranBarangTanggal: '',
        pembeliPenerima: '',
        negaraTujuan: '',
        kodeBarang: '',
        namaBarang: '',
        satuan: '',
        jumlah: '',
        mataUang: '',
        nilaiBarang: '',
    });

    useEffect(() => {
        fetchLaporan();
    }, []);

    const fetchLaporan = async () => {
        try {
            const response = await getLaporanById(id);
            setLaporan(response);
        } catch (error) {
            console.error("Error fetching laporan:", error);
        }
    };

    const handleChange = (e) => {
        setLaporan({
            ...laporan,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await updateLaporan(id, laporan);
            navigate('/laporan-pengeluaran-hasil-produksi');
        } catch (error) {
            console.error("Error updating laporan:", error);
        }
    };

    return (
        <div>
            <h2>Edit Laporan Pengeluaran Hasil Produksi</h2>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="pebNomor">
                    <Form.Label>PEB Nomor</Form.Label>
                    <Form.Control
                        type="text"
                        name="pebNomor"
                        value={laporan.pebNomor}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group controlId="pebTanggal">
                    <Form.Label>PEB Tanggal</Form.Label>
                    <Form.Control
                        type="date"
                        name="pebTanggal"
                        value={laporan.pebTanggal.split('T')[0]}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group controlId="buktiPengeluaranBarangNomor">
                    <Form.Label>Bukti Pengeluaran Barang Nomor</Form.Label>
                    <Form.Control
                        type="text"
                        name="buktiPengeluaranBarangNomor"
                        value={laporan.buktiPengeluaranBarangNomor}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group controlId="buktiPengeluaranBarangTanggal">
                    <Form.Label>Bukti Pengeluaran Barang Tanggal</Form.Label>
                    <Form.Control
                        type="date"
                        name="buktiPengeluaranBarangTanggal"
                        value={laporan.buktiPengeluaranBarangTanggal.split('T')[0]}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group controlId="pembeliPenerima">
                    <Form.Label>Pembeli Penerima</Form.Label>
                    <Form.Control
                        type="text"
                        name="pembeliPenerima"
                        value={laporan.pembeliPenerima}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group controlId="negaraTujuan">
                    <Form.Label>Negara Tujuan</Form.Label>
                    <Form.Control
                        type="text"
                        name="negaraTujuan"
                        value={laporan.negaraTujuan}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group controlId="kodeBarang">
                    <Form.Label>Kode Barang</Form.Label>
                    <Form.Control
                        type="text"
                        name="kodeBarang"
                        value={laporan.kodeBarang}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group controlId="namaBarang">
                    <Form.Label>Nama Barang</Form.Label>
                    <Form.Control
                        type="text"
                        name="namaBarang"
                        value={laporan.namaBarang}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group controlId="satuan">
                    <Form.Label>Satuan</Form.Label>
                    <Form.Control
                        type="text"
                        name="satuan"
                        value={laporan.satuan}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group controlId="jumlah">
                    <Form.Label>Jumlah</Form.Label>
                    <Form.Control
                        type="number"
                        name="jumlah"
                        value={laporan.jumlah}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group controlId="mataUang">
                    <Form.Label>Mata Uang</Form.Label>
                    <Form.Control
                        type="text"
                        name="mataUang"
                        value={laporan.mataUang}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group controlId="nilaiBarang">
                    <Form.Label>Nilai Barang</Form.Label>
                    <Form.Control
                        type="number"
                        name="nilaiBarang"
                        value={laporan.nilaiBarang}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Button variant="primary" type="submit" className="mt-3">
                    <i className="fas fa-save"></i> Save
                </Button>
            </Form>
        </div>
    );
};

export default EditLaporanPengeluaranHasilProduksi;
