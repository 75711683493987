// src/components/LaporanMutasiHasilProduksiEdit.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getLaporanById, createLaporan, updateLaporan } from '../../api/laporanMutasiHasilProduksiService';

const LaporanMutasiHasilProduksiEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        kodeBarang: '',
        namaBarang: '',
        satuan: '',
        saldoAwal: 0,
        pemasukan: 0,
        pengeluaran: 0,
        saldoAkhir: 0,
        gudang: ''
    });
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        if (id) {
            // Fetch existing laporan data if an id is provided
            fetchLaporanById(id);
        } else {
            setIsEditing(false);
        }
    }, [id]);

    const fetchLaporanById = async (id) => {
        try {
            const response = await getLaporanById(id);
            if (response) {
                setFormData(response);
                setIsEditing(true);
            }
        } catch (error) {
            console.error("Error fetching laporan by id:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (isEditing) {
                await updateLaporan(id, formData);
            } else {
                await createLaporan(formData);
            }
            navigate('/laporan-mutasi-hasil-produksi');
        } catch (error) {
            console.error("Error saving laporan:", error);
        }
    };

    return (
        <div>
            <h2>{isEditing ? 'Edit' : 'Create'} Laporan Mutasi Hasil Produksi</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="kodeBarang" className="form-label">Kode Barang</label>
                    <input
                        type="text"
                        className="form-control"
                        id="kodeBarang"
                        name="kodeBarang"
                        value={formData.kodeBarang}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="namaBarang" className="form-label">Nama Barang</label>
                    <input
                        type="text"
                        className="form-control"
                        id="namaBarang"
                        name="namaBarang"
                        value={formData.namaBarang}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="satuan" className="form-label">Satuan</label>
                    <input
                        type="text"
                        className="form-control"
                        id="satuan"
                        name="satuan"
                        value={formData.satuan}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="saldoAwal" className="form-label">Saldo Awal</label>
                    <input
                        type="number"
                        step="0.01"
                        className="form-control"
                        id="saldoAwal"
                        name="saldoAwal"
                        value={formData.saldoAwal}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="pemasukan" className="form-label">Pemasukan</label>
                    <input
                        type="number"
                        step="0.01"
                        className="form-control"
                        id="pemasukan"
                        name="pemasukan"
                        value={formData.pemasukan}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="pengeluaran" className="form-label">Pengeluaran</label>
                    <input
                        type="number"
                        step="0.01"
                        className="form-control"
                        id="pengeluaran"
                        name="pengeluaran"
                        value={formData.pengeluaran}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="saldoAkhir" className="form-label">Saldo Akhir</label>
                    <input
                        type="number"
                        step="0.01"
                        className="form-control"
                        id="saldoAkhir"
                        name="saldoAkhir"
                        value={formData.saldoAkhir}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="gudang" className="form-label">Gudang</label>
                    <input
                        type="text"
                        className="form-control"
                        id="gudang"
                        name="gudang"
                        value={formData.gudang}
                        onChange={handleChange}
                        required
                    />
                </div>
                <button type="submit" className="btn btn-primary">{isEditing ? 'Update' : 'Create'}</button>
            </form>
        </div>
    );
};

export default LaporanMutasiHasilProduksiEdit;
