// src/components/LaporanPemasukanHasilProduksi/EditLaporanPemasukanHasilProduksi.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getLaporanById, updateLaporan } from '../../api/laporanPemasukanHasilProduksiService';
import { Form, Button } from 'react-bootstrap';

const EditLaporanPemasukanHasilProduksi = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [laporan, setLaporan] = useState({
        buktiPemasukanNomor: '',
        buktiPemasukanTanggal: '',
        kodeBarang: '',
        namaBarang: '',
        satuan: '',
        jumlahDariProduksi: '',
        jumlahDariSubkontrak: '',
        gudang: '',
    });

    useEffect(() => {
        fetchLaporan();
    }, []);

    const fetchLaporan = async () => {
        try {
            const response = await getLaporanById(id);
            setLaporan(response);
        } catch (error) {
            console.error("Error fetching laporan:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLaporan((prevLaporan) => ({ ...prevLaporan, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await updateLaporan(id, laporan);
            navigate('/laporan-pemasukan-hasil-produksi');
        } catch (error) {
            console.error("Error updating laporan:", error);
        }
    };

    return (
        <div>
            <h2>Edit Laporan Pemasukan Hasil Produksi</h2>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="buktiPemasukanNomor">
                    <Form.Label>Bukti Pemasukan Nomor</Form.Label>
                    <Form.Control
                        type="text"
                        name="buktiPemasukanNomor"
                        value={laporan.buktiPemasukanNomor}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group controlId="buktiPemasukanTanggal">
                    <Form.Label>Bukti Pemasukan Tanggal</Form.Label>
                    <Form.Control
                        type="date"
                        name="buktiPemasukanTanggal"
                        value={laporan.buktiPemasukanTanggal.split('T')[0]}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group controlId="kodeBarang">
                    <Form.Label>Kode Barang</Form.Label>
                    <Form.Control
                        type="text"
                        name="kodeBarang"
                        value={laporan.kodeBarang}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group controlId="namaBarang">
                    <Form.Label>Nama Barang</Form.Label>
                    <Form.Control
                        type="text"
                        name="namaBarang"
                        value={laporan.namaBarang}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group controlId="satuan">
                    <Form.Label>Satuan</Form.Label>
                    <Form.Control
                        type="text"
                        name="satuan"
                        value={laporan.satuan}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group controlId="jumlahDariProduksi">
                    <Form.Label>Jumlah Dari Produksi</Form.Label>
                    <Form.Control
                        type="number"
                        name="jumlahDariProduksi"
                        value={laporan.jumlahDariProduksi}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group controlId="jumlahDariSubkontrak">
                    <Form.Label>Jumlah Dari Subkontrak</Form.Label>
                    <Form.Control
                        type="number"
                        name="jumlahDariSubkontrak"
                        value={laporan.jumlahDariSubkontrak}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group controlId="gudang">
                    <Form.Label>Gudang</Form.Label>
                    <Form.Control
                        type="text"
                        name="gudang"
                        value={laporan.gudang}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Button variant="primary" type="submit" className="mt-3">
                    Update Laporan
                </Button>
            </Form>
        </div>
    );
};

export default EditLaporanPemasukanHasilProduksi;
