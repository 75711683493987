// src/pages/Home.js
import React from 'react';

const Home = () => {

  const environment = process.env.REACT_APP_API_URL;

  return (
    <div className="mt-5">
      <h2>Home</h2>
      <p>Welcome to the home page! </p>
      API : {environment}
      
      {environment === 'development' && (
        <div style={{ padding: '10px', backgroundColor: 'yellow', color: 'black' }}>
          <strong>Note:</strong> This application is running in <strong>development</strong> mode.
        </div>
      )}
      {environment === 'production' && (
        <div style={{ padding: '10px', backgroundColor: 'lightgreen', color: 'black' }}>
          <strong>Note:</strong> This application is running in <strong>production</strong> mode.
        </div>
      )}
    </div>
  );
};

export default Home;
