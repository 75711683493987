// src/components/LaporanMutasiHasilProduksiList.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getLaporan, deleteLaporan } from '../../api/laporanMutasiHasilProduksiService';
import * as XLSX from 'xlsx';
import { Table, Button, Pagination } from 'react-bootstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';

const LaporanMutasiHasilProduksiList = () => {
    const [laporan, setLaporan] = useState([]);
    const [filteredLaporan, setFilteredLaporan] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [tempStartDate, setTempStartDate] = useState('');
    const [tempEndDate, setTempEndDate] = useState('');
    const [filtered, setFiltered] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [sortField, setSortField] = useState('namaBarang');
    const [sortOrder, setSortOrder] = useState('asc');
    const navigate = useNavigate();

    useEffect(() => {
        fetchLaporan();
    }, [currentPage, sortField, sortOrder]);

    useEffect(() => {
        applyFilter();
    }, [tempStartDate, tempEndDate, laporan]);

    const fetchLaporan = async () => {
        try {
            const filters = {};
            if (startDate) filters.startDate = startDate;
            if (endDate) filters.endDate = endDate;

            const response = await getLaporan(filters);
            if (Array.isArray(response)) {
                setLaporan(response);
            } else {
                console.error("Expected an array but got:", typeof response);
                setLaporan([]);
                setFilteredLaporan([]);
            }
        } catch (error) {
            console.error("Error fetching laporan:", error);
            setLaporan([]);
            setFilteredLaporan([]);
        }
    };

    const applyFilter = () => {
        let filteredData = laporan;
        if (tempStartDate) {
            filteredData = filteredData.filter(item => new Date(item.tanggal) >= new Date(tempStartDate));
        }
        if (tempEndDate) {
            filteredData = filteredData.filter(item => new Date(item.tanggal) <= new Date(tempEndDate));
        }
        setFilteredLaporan(filteredData);
    };

    const handleFilter = () => {
        setStartDate(tempStartDate);
        setEndDate(tempEndDate);
        setFiltered(true);
    };

    const handleReset = () => {
        setStartDate('');
        setEndDate('');
        setTempStartDate('');
        setTempEndDate('');
        setFiltered(false);
        fetchLaporan();
    };

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(
            filteredLaporan.map((laporan, index) => ({
                No: index + 1,
                KodeBarang: laporan.kodeBarang,
                NamaBarang: laporan.namaBarang,
                Satuan: laporan.satuan,
                SaldoAwal: laporan.saldoAwal,
                Pemasukan: laporan.pemasukan,
                Pengeluaran: laporan.pengeluaran,
                SaldoAkhir: laporan.saldoAkhir,
                Gudang: laporan.gudang,
            }))
        );
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Laporan Mutasi Hasil Produksi');
        XLSX.writeFile(workbook, 'laporan_mutasi_hasil_produksi.xlsx');
    };

    const handleDelete = async (id) => {
        try {
            await deleteLaporan(id);
            fetchLaporan();
        } catch (error) {
            console.error("Error deleting laporan:", error);
        }
    };

    const handleEdit = (id) => {
        navigate(`/laporan-mutasi-hasil-produksi/edit/${id}`);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSort = (field) => {
        const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(order);
    };

    const getSortedData = () => {
        const sortedData = [...filteredLaporan].sort((a, b) => {
            if (a[sortField] < b[sortField]) return sortOrder === 'asc' ? -1 : 1;
            if (a[sortField] > b[sortField]) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });
        return sortedData;
    };

    const getPagedData = () => {
        const sortedData = getSortedData();
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return sortedData.slice(startIndex, endIndex);
    };

    const totalPages = Math.ceil(filteredLaporan.length / itemsPerPage);

    return (
        <div>
            <h2>Laporan Mutasi Hasil Produksi</h2>

            <div className="mb-3">
                <label htmlFor="startDate" className="form-label">Start Date</label>
                <input
                    type="date"
                    id="startDate"
                    value={tempStartDate}
                    onChange={(e) => setTempStartDate(e.target.value)}
                    className="form-control"
                />
            </div>

            <div className="mb-3">
                <label htmlFor="endDate" className="form-label">End Date</label>
                <input
                    type="date"
                    id="endDate"
                    value={tempEndDate}
                    onChange={(e) => setTempEndDate(e.target.value)}
                    className="form-control"
                />
            </div>

            <Button onClick={handleFilter} className="btn btn-primary">Filter</Button>
            <Button onClick={handleReset} className="btn btn-secondary ms-2">Reset Filter</Button>
            {filtered && (
                <Button onClick={exportToExcel} className="btn btn-success ms-2">Export to Excel</Button>
            )}

            {filtered && (
                <div className="table-responsive mt-3">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th onClick={() => handleSort('kodeBarang')}>
                                    Kode Barang
                                    {sortField === 'kodeBarang' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                </th>
                                <th onClick={() => handleSort('namaBarang')}>
                                    Nama Barang
                                    {sortField === 'namaBarang' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                </th>
                                <th onClick={() => handleSort('satuan')}>
                                    Satuan
                                    {sortField === 'satuan' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                </th>
                                <th onClick={() => handleSort('saldoAwal')}>
                                    Saldo Awal
                                    {sortField === 'saldoAwal' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                </th>
                                <th onClick={() => handleSort('pemasukan')}>
                                    Pemasukan
                                    {sortField === 'pemasukan' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                </th>
                                <th onClick={() => handleSort('pengeluaran')}>
                                    Pengeluaran
                                    {sortField === 'pengeluaran' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                </th>
                                <th onClick={() => handleSort('saldoAkhir')}>
                                    Saldo Akhir
                                    {sortField === 'saldoAkhir' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                </th>
                                <th onClick={() => handleSort('gudang')}>
                                    Gudang
                                    {sortField === 'gudang' && (sortOrder === 'asc' ? <i className="fas fa-arrow-up ms-2"></i> : <i className="fas fa-arrow-down ms-2"></i>)}
                                </th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getPagedData().map((item) => (
                                <tr key={item.uuid}>
                                    <td>{item.kodeBarang}</td>
                                    <td>{item.namaBarang}</td>
                                    <td>{item.satuan}</td>
                                    <td style={{ textAlign: 'right' }}>{item.saldoAwal}</td>
                                    <td style={{ textAlign: 'right' }}>{item.pemasukan}</td>
                                    <td style={{ textAlign: 'right' }}>{item.pengeluaran}</td>
                                    <td style={{ textAlign: 'right' }}>{item.saldoAkhir}</td>
                                    <td>{item.gudang}</td>
                                    <td>
                                        <Button onClick={() => handleEdit(item.id)} className="btn btn-warning btn-sm me-2">Edit</Button>
                                        <Button onClick={() => handleDelete(item.id)} className="btn btn-danger btn-sm">Delete</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Pagination>
                        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                        {[...Array(totalPages)].map((_, index) => (
                            <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => handlePageChange(index + 1)}>
                                {index + 1}
                            </Pagination.Item>
                        ))}
                        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                    </Pagination>
                </div>
            )}
        </div>
    );
};

export default LaporanMutasiHasilProduksiList;
