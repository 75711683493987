// src/components/LaporanWaste/LaporanWasteEdit.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getLaporanById, updateLaporan } from '../../api/laporanWasteService';
import { Button, Form } from 'react-bootstrap';

const LaporanWasteEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [laporan, setLaporan] = useState({
        NomorBC: '',
        tanggalBC: '',
        kodeBarang: '',
        namaBarang: '',
        Satuan: '',
        Jumlah: '',
        Nilai: '',
    });

    useEffect(() => {
        fetchLaporan();
    }, []);

    const fetchLaporan = async () => {
        try {
            const response = await getLaporanById(id);
            setLaporan(response);
        } catch (error) {
            console.error('Error fetching laporan:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLaporan({ ...laporan, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await updateLaporan(id, laporan);
            navigate('/laporan-waste');
        } catch (error) {
            console.error('Error updating laporan:', error);
        }
    };

    return (
        <div>
            <h2>Edit Laporan Waste</h2>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label>Nomor BC</Form.Label>
                    <Form.Control
                        type="text"
                        name="NomorBC"
                        value={laporan.NomorBC}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Tanggal BC</Form.Label>
                    <Form.Control
                        type="date"
                        name="tanggalBC"
                        value={laporan.tanggalBC}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Kode Barang</Form.Label>
                    <Form.Control
                        type="text"
                        name="kodeBarang"
                        value={laporan.kodeBarang}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Nama Barang</Form.Label>
                    <Form.Control
                        type="text"
                        name="namaBarang"
                        value={laporan.namaBarang}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Satuan</Form.Label>
                    <Form.Control
                        type="text"
                        name="Satuan"
                        value={laporan.Satuan}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Jumlah</Form.Label>
                    <Form.Control
                        type="number"
                        name="Jumlah"
                        value={laporan.Jumlah}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Nilai</Form.Label>
                    <Form.Control
                        type="number"
                        name="Nilai"
                        value={laporan.Nilai}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Button type="submit" className="btn btn-primary mt-3">
                    Save
                </Button>
            </Form>
        </div>
    );
};

export default LaporanWasteEdit;
