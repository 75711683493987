import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getLaporanById, updateLaporan } from '../../api/laporanKegiatanSubkontrakService';

const EditLaporanKegiatanSubkontrak = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [laporan, setLaporan] = useState(null);

    useEffect(() => {
        fetchLaporan();
    }, []);

    const fetchLaporan = async () => {
        try {
            const response = await getLaporanById(id);
            setLaporan(response);
        } catch (error) {
            console.error("Error fetching laporan:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLaporan({ ...laporan, [name]: value });
    };

    const handleSave = async () => {
        try {
            await updateLaporan(id, laporan);
            navigate('/laporan-kegiatan-subkontrak');
        } catch (error) {
            console.error("Error updating laporan:", error);
        }
    };

    if (!laporan) return <div>Loading...</div>;

    return (
        <div>
            <h3>Edit Laporan Kegiatan Subkontrak</h3>
            <form>
                <div className="form-group">
                    <label>Tanggal Bukti Pengeluaran Barang</label>
                    <input
                        type="date"
                        name="TanggalBuktiPengeluaranBarang"
                        value={new Date(laporan.TanggalBuktiPengeluaranBarang).toISOString().split('T')[0]}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Nomor Bukti Pengeluaran Barang</label>
                    <input
                        type="text"
                        name="NomorBuktiPengeluaranBarang"
                        value={laporan.NomorBuktiPengeluaranBarang}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Kode Barang</label>
                    <input
                        type="text"
                        name="kodeBarang"
                        value={laporan.kodeBarang}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Nama Barang</label>
                    <input
                        type="text"
                        name="namaBarang"
                        value={laporan.namaBarang}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Satuan</label>
                    <input
                        type="text"
                        name="Satuan"
                        value={laporan.Satuan}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Disubkontrakkan</label>
                    <input
                        type="text"
                        name="Disubkontrakkan"
                        value={laporan.Disubkontrakkan}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Penerima Sub Kontrak</label>
                    <input
                        type="text"
                        name="PenerimaSubKontrak"
                        value={laporan.PenerimaSubKontrak}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <button type="button" onClick={handleSave} className="btn btn-primary">Save</button>
            </form>
        </div>
    );
};

export default EditLaporanKegiatanSubkontrak;
