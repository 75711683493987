import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getLaporanById, updateLaporan } from '../../api/laporanMutasiBahanBakuService';
import { Button, Form } from 'react-bootstrap';

const LaporanMutasiBahanBakuEdit = () => {
    const [laporan, setLaporan] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        const fetchLaporan = async () => {
            try {
                const data = await getLaporanById(id);
                setLaporan(data);
                setLoading(false);
            } catch (error) {
                setError('Failed to fetch laporan');
                setLoading(false);
            }
        };

        fetchLaporan();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLaporan((prevLaporan) => ({
            ...prevLaporan,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await updateLaporan(id, laporan);
            navigate('/laporan-mutasi-bahan-baku');
        } catch (error) {
            setError('Failed to update laporan');
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div>
            <h2>Edit Laporan Mutasi Bahan Baku</h2>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                    <Form.Label>Kode Barang</Form.Label>
                    <Form.Control
                        type="text"
                        name="kodeBarang"
                        value={laporan.kodeBarang || ''}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Nama Barang</Form.Label>
                    <Form.Control
                        type="text"
                        name="namaBarang"
                        value={laporan.namaBarang || ''}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Satuan</Form.Label>
                    <Form.Control
                        type="text"
                        name="satuan"
                        value={laporan.satuan || ''}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Saldo Awal</Form.Label>
                    <Form.Control
                        type="number"
                        name="saldoAwal"
                        value={laporan.saldoAwal || ''}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Pemasukan</Form.Label>
                    <Form.Control
                        type="number"
                        name="pemasukan"
                        value={laporan.pemasukan || ''}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Pengeluaran</Form.Label>
                    <Form.Control
                        type="number"
                        name="pengeluaran"
                        value={laporan.pengeluaran || ''}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Saldo Akhir</Form.Label>
                    <Form.Control
                        type="number"
                        name="saldoAkhir"
                        value={laporan.saldoAkhir || ''}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Gudang</Form.Label>
                    <Form.Control
                        type="text"
                        name="gudang"
                        value={laporan.gudang || ''}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Button type="submit" className="btn btn-primary">Update</Button>
            </Form>
        </div>
    );
};

export default LaporanMutasiBahanBakuEdit;
