// src/components/Customers/AddCustomer.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory
import { createCustomer } from '../../api/customerService'; // Correct import statement for named export

const AddCustomer = () => {
  const navigate = useNavigate(); // Replace useHistory with useNavigate
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [error, setError] = useState('');

  const handleAddCustomer = async (e) => {
    e.preventDefault();
    try {
      await createCustomer({ name, email, phone, address }); // Use createCustomer function from customerService
      navigate('/customers'); // Redirect to customers list after adding
    } catch (error) {
      console.error('Failed to add customer:', error);
      setError('Failed to add customer. Please try again.'); // Example error handling
    }
  };

  return (
    <div className="container">
      <h2>Add Customer</h2>
      {error && <div className="alert alert-danger">{error}</div>}
      <form onSubmit={handleAddCustomer}>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">Name</label>
          <input type="text" className="form-control" id="name" value={name} onChange={(e) => setName(e.target.value)} required />
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">Email</label>
          <input type="email" className="form-control" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </div>
        <div className="mb-3">
          <label htmlFor="phone" className="form-label">Phone</label>
          <input type="text" className="form-control" id="phone" value={phone} onChange={(e) => setPhone(e.target.value)} required />
        </div>
        <div className="mb-3">
          <label htmlFor="address" className="form-label">Address</label>
          <textarea className="form-control" id="address" value={address} onChange={(e) => setAddress(e.target.value)} required />
        </div>
        <button type="submit" className="btn btn-primary">Add Customer</button>
      </form>
    </div>
  );
};

export default AddCustomer;
