// src/api/customerService.js

import axiosInstance from './axiosInstance';

const getAllCustomers = async (criteria = {}) => {
  try {
    const response = await axiosInstance.get('/api/customers', { params: criteria });
    return response.data;
  } catch (error) {
    throw error.response.data.message || error.message;
  }
};

const getCustomerById = async (id) => {
  try {
    const response = await axiosInstance.get(`/api/customers/${id}`);
    return response.data;
  } catch (error) {
    throw error.response.data.message || error.message;
  }
};

const createCustomer = async (customerData) => {
  try {
    const response = await axiosInstance.post('/api/customers', customerData);
    return response.data;
  } catch (error) {
    throw error.response.data.message || error.message;
  }
};

const updateCustomer = async (id, customerData) => {
  try {
    const response = await axiosInstance.put(`/api/customers/${id}`, customerData);
    return response.data;
  } catch (error) {
    throw error.response.data.message || error.message;
  }
};

const deleteCustomer = async (id) => {
  try {
    const response = await axiosInstance.delete(`/api/customers/${id}`);
    return response.data;
  } catch (error) {
    throw error.response.data.message || error.message;
  }
};

export { getAllCustomers, getCustomerById, createCustomer, updateCustomer, deleteCustomer };
