import axiosInstance from './axiosInstance';

export const getLaporanById = async (id) => {
    try {
        const response = await axiosInstance.get(`/api/laporanPengeluaranHasilProduksi/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching laporan by ID:', error);
        return null;
    }
};

export const updateLaporan = async (id, data) => {
    try {
        const response = await axiosInstance.put(`/api/laporanPengeluaranHasilProduksi/${id}`, data);
        return response.data;
    } catch (error) {
        console.error('Error updating laporan:', error);
        throw error;
    }
};

export const getLaporan = async (filters = {}) => {
    try {
        const response = await axiosInstance.get('/api/laporanPengeluaranHasilProduksi', { params: filters });
        console.log('getLaporan response:', response.data); // Log the response data
        return response.data;
    } catch (error) {
        console.error('Error fetching laporan:', error);
        return [];
    }
};

export const deleteLaporan = async (id) => {
    try {
        const response = await axiosInstance.delete(`/api/laporanPengeluaranHasilProduksi/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting laporan:', error);
        throw error;
    }
};
