import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Button, Table } from 'react-bootstrap';
import { useTable, useSortBy, usePagination } from 'react-table';
import * as XLSX from 'xlsx';
import CustomerFilterForm from './CustomerFilterForm';
import { getAllCustomers, deleteCustomer } from '../../api/customerService';

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [showFilterForm, setShowFilterForm] = useState(true);
  const [criteria, setCriteria] = useState({});

  const fetchCustomers = useCallback(async (criteria) => {
    try {
      const data = await getAllCustomers(criteria);
      setCustomers(data);
    } catch (error) {
      console.error('Failed to fetch customers:', error);
    }
  }, []);

  useEffect(() => {
    fetchCustomers(criteria);
  }, [criteria, fetchCustomers]);

  const handleDeleteCustomer = useCallback(async (id) => {
    try {
      await deleteCustomer(id);
      setCustomers((prevCustomers) => prevCustomers.filter(customer => customer.id !== id));
    } catch (error) {
      console.error('Failed to delete customer:', error);
    }
  }, []);

  const handleFilterSubmit = useCallback((criteria) => {
    setCriteria(criteria);
    setShowFilterForm(false);
  }, []);

  const resetFilter = () => {
    setShowFilterForm(true);
    setCriteria({});
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Phone',
        accessor: 'phone',
      },
      {
        Header: 'Address',
        accessor: 'address',
      },
      {
        Header: 'Actions',
        Cell: ({ row }) => (
          <>
            <Link className="btn btn-sm btn-info me-1" to={`/customers/edit/${row.original.id}`}>Edit</Link>
            <Button variant="danger" size="sm" onClick={() => handleDeleteCustomer(row.original.id)}>Delete</Button>
          </>
        ),
      },
    ],
    [handleDeleteCustomer]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data: customers,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useSortBy,
    usePagination
  );

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(customers);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Customers');
    XLSX.writeFile(workbook, 'customers.xlsx');
  };

  return (
    <div className="container">
      {showFilterForm ? (
        <CustomerFilterForm onSubmit={handleFilterSubmit} />
      ) : (
        <>
          <h2>Customers</h2>
          <Button variant="primary" className="mb-3" onClick={() => setShowFilterForm(true)}>Change Filter</Button>
          <Link className="btn btn-primary mb-3 ms-3" to="/customers/add">Add Customer</Link>
          <Button className="mb-3 ms-3" variant="success" onClick={exportToExcel}>Export to Excel</Button>
          
          {/* Add the Reset Filters button */}
          <Button variant="light" className="mb-3 ms-3" onClick={resetFilter}>Reset Filters</Button>

          <Table {...getTableProps()} striped bordered hover>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th key={column.id} {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row);
                return (
                  <tr key={row.id} {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <td key={cell.column.id} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </Table>

          <div className="pagination">
            <Button variant="light" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</Button>
            <Button variant="light" onClick={previousPage} disabled={!canPreviousPage}>Previous</Button>
            <Button variant="light" onClick={nextPage} disabled={!canNextPage}>Next</Button>
            <Button variant="light" onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}>{'>>'}</Button>
            <span>
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{' '}
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default Customers;
