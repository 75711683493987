// src/api/axiosInstance.js

import axios from 'axios';
import { refreshToken as getNewToken } from '../utils/auth';

const API_URL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: API_URL,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Check if the error status is 401 (Unauthorized) and the request hasn't been retried yet
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        // Get new access token using the refresh token
        const newAccessToken = await getNewToken();

        // Update the Authorization header with the new access token
        axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

        // Retry the original request with the new access token
        return axiosInstance(originalRequest);
      } catch (err) {
        return Promise.reject(err);
      }
    }

    // If the error status is 403 (Forbidden), handle it appropriately
    if (error.response.status === 403) {
      console.error('Access forbidden. Please check your permissions.');
      // Optionally, you could redirect to a login page or show a message to the user ***

      // Optionally, redirect to the login page or clear local storage here
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      // Replace with your own logic to redirect to the login page
      window.location.replace('/login'); // Example redirect to login page

    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
