// src/components/LaporanPemakaianBahanBaku/LaporanPemakaianEdit.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getLaporanById, updateLaporan } from '../../api/laporanPemakaianBahanBakuService';
import { Button, Form } from 'react-bootstrap';

const LaporanPemakaianEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [laporan, setLaporan] = useState({
        buktiPengeluaranNomor: '',
        buktiPengeluaranTanggal: '',
        kodeBarang: '',
        namaBarang: '',
        satuan: '',
        jumlahDigunakan: '',
        jumlahDisubkontrakkan: '',
        penerimaSubkontrak: '',
    });

    useEffect(() => {
        fetchLaporan();
    }, []);

    const fetchLaporan = async () => {
        try {
            const response = await getLaporanById(id);
            setLaporan(response);
        } catch (error) {
            console.error('Error fetching laporan:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLaporan({ ...laporan, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await updateLaporan(id, laporan);
            navigate('/laporan-pemakaian-bahan-baku');
        } catch (error) {
            console.error('Error updating laporan:', error);
        }
    };

    return (
        <div>
            <h2>Edit Laporan Pemakaian Bahan Baku</h2>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label>Bukti Pengeluaran Nomor</Form.Label>
                    <Form.Control
                        type="text"
                        name="buktiPengeluaranNomor"
                        value={laporan.buktiPengeluaranNomor}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Bukti Pengeluaran Tanggal</Form.Label>
                    <Form.Control
                        type="date"
                        name="buktiPengeluaranTanggal"
                        value={laporan.buktiPengeluaranTanggal}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Kode Barang</Form.Label>
                    <Form.Control
                        type="text"
                        name="kodeBarang"
                        value={laporan.kodeBarang}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Nama Barang</Form.Label>
                    <Form.Control
                        type="text"
                        name="namaBarang"
                        value={laporan.namaBarang}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Satuan</Form.Label>
                    <Form.Control
                        type="text"
                        name="satuan"
                        value={laporan.satuan}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Jumlah Digunakan</Form.Label>
                    <Form.Control
                        type="number"
                        name="jumlahDigunakan"
                        value={laporan.jumlahDigunakan}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Jumlah Disubkontrakkan</Form.Label>
                    <Form.Control
                        type="number"
                        name="jumlahDisubkontrakkan"
                        value={laporan.jumlahDisubkontrakkan}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Penerima Subkontrak</Form.Label>
                    <Form.Control
                        type="text"
                        name="penerimaSubkontrak"
                        value={laporan.penerimaSubkontrak}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Button type="submit" className="btn btn-primary mt-3">
                    Save
                </Button>
            </Form>
        </div>
    );
};

export default LaporanPemakaianEdit;
